<ion-app>
  <ion-menu side="start" contentId="main-content">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list>
        <ion-menu-toggle>

          <ion-item routerLink="/home" routerDirection="root">
            <ion-icon slot="start" name="home"></ion-icon>
            <ion-label icon="home">Home</ion-label>
          </ion-item>

          <ion-item routerLink="/ingresos" routerDirection="root">
            <ion-icon slot="start" name="analytics"></ion-icon>
            <ion-label>Captura de ingresos y egresos</ion-label>
          </ion-item>

          <ion-item routerLink="/ingresos-egresos" routerDirection="root">
            <ion-icon slot="start" name="bar-Chart"></ion-icon>
            <ion-label>Ingresos / Egresos</ion-label>
          </ion-item>

          <!-- <ion-item routerLink="/ingresos-api" routerDirection="root">
            <ion-icon slot="start" name="bar-Chart"></ion-icon>
            <ion-label>Importar ingresos</ion-label>
          </ion-item> -->

          <ion-item routerLink="/reconciliaciones" routerDirection="root">
            <ion-icon slot="start" name="folder"></ion-icon>
            <ion-label>Reconciliaciones</ion-label>
          </ion-item>

          <ion-item routerLink="/reconciliaciones-historial" routerDirection="root">
            <ion-icon slot="start" name="folder"></ion-icon>
            <ion-label>Reconciliaciones historial</ion-label>
          </ion-item>

          <ion-item routerLink="/transferencias" routerDirection="root">
            <ion-icon slot="start" name="swap-horizontal-outline"></ion-icon>
            <ion-label>Transferencias</ion-label>
          </ion-item>

          <ion-item routerLink="/combinaciones" routerDirection="root">
            <ion-icon slot="start" name="git-compare"></ion-icon>
            <ion-label>Combinaciones</ion-label>
          </ion-item>

          <ion-item *ngIf="isAdmin" button (click)="navigateTo('proveedores')">
            <ion-icon slot="start" [name]="'people-outline'"></ion-icon>
            <ion-label>Proveedores</ion-label>
          </ion-item>

          <ion-item *ngIf="isAdmin" button (click)="navigateTo('presupuesto')">
            <ion-icon slot="start" [name]="'cash-outline'"></ion-icon>
            <ion-label>Presupuesto</ion-label>
          </ion-item>

          <ion-item *ngIf="isAdmin" button (click)="navigateTo('presupuesto-mensual')">
            <ion-icon slot="start" [name]="'cash-outline'"></ion-icon>
            <ion-label>Presupuesto mensual</ion-label>
          </ion-item>

          <ion-item *ngIf="isAdmin" button (click)="navigateTo('usuarios')">
            <ion-icon slot="start" [name]="'people-outline'"></ion-icon>
            <ion-label>Usuarios</ion-label>
          </ion-item>

          <!-- <ion-item routerLink="/proyeccion" routerDirection="root">
            <ion-icon slot="start" name="home"></ion-icon>
            <ion-label icon="home">Proyeccion</ion-label>
          </ion-item> -->

          <ion-item style="margin-top: 2rem;" (click)="logout()" lines="none">
            <ion-icon slot="start" name="log-out" color="danger"></ion-icon>
            <ion-label color="danger">Cerrar Sesión</ion-label>
          </ion-item>

        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>


  <ion-header *ngIf="isLoggedIn">
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title style="cursor: pointer; width: fit-content;" (click)="navigateTo('home')">Home</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="toggleNotifications()" class="notification-button">
          <div class="notification-icon">
            <ion-icon slot="icon-only" name="notifications-outline"></ion-icon>
            <span class="notification-badge">{{ notifications.length }}</span>
          </div>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>



  <!-- Barra de carga con barra de progreso -->
  <div *ngIf="isLoading" class="loading-bar">
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
    <p>Cargando registros, por favor espere...</p>
  </div>


  <ion-router-outlet id="main-content"></ion-router-outlet>


  <ion-popover [isOpen]="isNotificationsOpen" (ionPopoverDidDismiss)="isNotificationsOpen = false">
    <ng-template>
      <ion-list>
        <ion-item style="cursor: pointer;" *ngFor="let notification of notifications" (click)="navigateTo(notification.route)">
          <ion-icon [style.color]="getIconColor(notification.icon)" [name]="notification.icon" slot="start"></ion-icon>
          <ion-label>{{ notification.message }}</ion-label>
        </ion-item>
      </ion-list>
    </ng-template>
  </ion-popover>


</ion-app>
